export default [
  {
    header: "Pages",
  },
  {
    title: "DASHBOARD",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "MESSAGE",
    icon: "MessageSquareIcon",
    children: [
      {
        title: "APP_PUSH",
        route: "message-app-push-list",
      },
      // {
      //   title: 'INSTAGRAM',
      //   route: 'apps-sns-chat',
      // },
      // {
      //   title: 'SENT_SMS_LIST',
      //   route: 'sent-sms-list',
      // },
      {
        title: "NOTIFICATION_LIST",
        route: "message-notification-list",
      },
      {
        title: "SMS_QUEUE",
        route: "message-sms-queue-list",
      },
      {
        title: "SMS_RESULT",
        route: "message-sms-result-list",
      },
      {
        title: "DM 그룹",
        route: "message-dm-group-list",
      },
    ],
  },
  {
    title: "MEMBER",
    icon: "UserIcon",
    children: [
      {
        title: "INFLUENCER",
        route: "member-inf-list",
      },
      {
        title: "ADVERTISER",
        route: "member-adv-list",
      },
      {
        title: "ADMIN",
        route: "member-adm-list",
      },
      {
        title: "SNS",
        route: "member-sns-list",
      },
      {
        title: "BRAND SNS",
        route: "brand-sns-list",
      },
      {
        title: "INF_RECOMMEND",
        route: "member-inf-recommend-list",
      },
      // {
      //   title: 'INF_TYPE',
      //   route: 'member-inf-type-list',
      // },
    ],
  },
  {
    title: "CAMPAIGN",
    icon: "InstagramIcon",
    children: [
      {
        title: "COMPT",
        route: "campaign-compt-list",
      },
      {
        title: "EXPG",
        route: "expg-list",
      },
      {
        title: "RESOLUTION",
        route: "claim-list",
      },
      {
        title: "SUGGESTION",
        route: "campaign-suggestion-list",
      },
      {
        title: "SCREENSHOT_JOB",
        route: "screenshot-job-list",
      },
      {
        title: "SCREENSHOT_LOG",
        route: "screenshot-log-list",
      },
      {
        title: "숨김캠페인",
        route: "campaign-hidden-list",
      },
    ],
  },
  {
    title: "FINANCE",
    icon: "DollarSignIcon",
    children: [
      {
        title: "EXCHANGE",
        route: "point-exchange-list",
      },
      {
        title: "INF_ADJUST_EXCHANGE",
        route: "inf-adjust-exchange-list",
      },
      {
        title: "POINT",
        route: "point-point-list",
      },
      {
        title: "PAY",
        route: "pay-list",
        // icon: 'CreditCardIcon',
      },
      {
        title: "RECEIPT",
        route: "finace-receipt-list",
      },
    ],
  },
  {
    title: "COMMERCE",
    icon: "ShoppingCartIcon",
    children: [
      // {
      //   title: "상품(legacy)",
      //   route: "commerce-legacy-product-list",
      // },
      {
        title: "PRODUCT",
        route: "commerce-product-list",
      },
      {
        title: "PRODUCT_UPDATE",
        route: "commerce-product-update-list",
      },
      {
        title: "ORDER",
        route: "commerce-order-re-list",
      },
      // {
      //   title: "Store.Adj(legacy)",
      //   route: "commerce-store-adjust",
      // },
      {
        title: "MYSTORE",
        route: "commerce-mystore-list",
      },
      {
        title: "STORE_ADJ",
        route: "commerce-store-adj-list",
      },
    ],
  },
  {
    title: "APP_MANAGE",
    icon: "BoxIcon",
    children: [
      {
        title: "WIDGET",
        route: "app-manage-widget-re-list",
      },
    ],
  },
  {
    title: "WEB_MANAGE",
    icon: "GlobeIcon",
    children: [
      {
        title: "업데이트 모달(legacy)",
        route: "update-content-list",
      },
      {
        title: "UPDATE_CONTENT",
        route: "update-content-re-list",
      },
      {
        title: "NOTICE",
        route: "web-manage-notice-list",
      },
    ],
  },
  {
    title: "태그바이링크",
    icon: "LinkIcon",
    children: [
      {
        title: "제안",
        route: "tagby-link-suggestion-list",
      },
    ],
  },
  {
    title: "TRACKING_CODE",
    route: "tracking-code-list",
    icon: "TrendingUpIcon",
  },
  {
    title: "BANNER_ADJUST",
    route: "banner-adjust",
    icon: "CreditCardIcon",
  },
  {
    title: "LEAD",
    route: "lead-list",
    icon: "MailIcon",
  },
  {
    title: "LABELING",
    icon: "EditIcon",
    route: "labeling-create",
  },
  // {
  //   title: "캠페인추천(테스트)",
  //   icon: "EditIcon",
  //   route: "campaign-recommend-test",
  // },
]

/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import {
  computed,
} from '@vue/composition-api'
import store from '@/store'
import tagbyMenu from './tagby'
import exampleMenu from './example'

const navItems = computed(() => {
  // For LABELLER
  if (store.getters['verticalMenu/isLabellerMenu']) {
    return tagbyMenu.filter(item => item.title === 'LABELING')
  }

  // For ADMIN
  if (store.getters['verticalMenu/isExampleMenu']) {
    return [...exampleMenu]
  }
  return [...tagbyMenu]
})

export default navItems

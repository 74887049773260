export default [
  {
    header: 'Core',
  },
  {
    title: 'Form Elements',
    icon: 'FolderIcon',
    children: [
      { title: 'Select', route: 'forms-element-select' },
      { title: 'Switch', route: 'forms-element-switch' },
      { title: 'Checkbox', route: 'forms-element-checkbox' },
      { title: 'Radio', route: 'forms-element-radio' },
      { title: 'Input', route: 'forms-element-input' },
      { title: 'Textarea', route: 'forms-element-textarea' },
      { title: 'Spinbutton', route: 'forms-element-spinbutton' },
      { title: 'Input Group', route: 'forms-element-input-group' },
      { title: 'Form Rating', route: 'forms-element-rating' },
      { title: 'Form Tag', route: 'forms-element-tag' },
      { title: 'Form Datepicker', route: 'forms-element-datepicker' },
      { title: 'Form Timepicker', route: 'forms-element-timepicker' },
      { title: 'File Input', route: 'forms-element-file-input' },
      { title: 'Auto Suggest', route: 'extensions-auto-suggest' },
      { title: 'Vue Select', route: 'extensions-vue-select' },
      { title: 'Quill Editor', route: 'extensions-quill-editor' },
      { title: 'Date Time Picker', route: 'extensions-date-time-picker' },
      { title: 'Input Mask', route: 'forms-element-input-mask' },
      { title: 'Form Layout', route: 'form-layout' },
      { title: 'Form Wizard', route: 'form-wizard' },
      { title: 'Form Validation', route: 'form-validation' },
      { title: 'Form Repeater', route: 'form-repeater' },
    ],
  },
  {
    title: 'Table',
    icon: 'FolderIcon',
    children: [
      { title: 'BS Table', route: 'table-bs-table' },
      { title: 'Good Table', route: 'table-good-table' },
    ],
  },
  {
    title: 'Components',
    icon: 'FolderIcon',
    children: [
      { title: 'Alerts', route: 'components-alert' },
      { title: 'Aspect', route: 'components-aspect' },
      { title: 'Avatar', route: 'components-avatar' },
      { title: 'Badge', route: 'components-badge' },
      { title: 'Breadcrumb', route: 'components-breadcrumb' },
      { title: 'Button', route: 'components-button' },
      { title: 'Button Group', route: 'components-button-group' },
      { title: 'Button Toolbar', route: 'components-button-toolbar' },
      { title: 'Calendar', route: 'components-calendar' },
      { title: 'Carousel', route: 'components-carousel' },
      { title: 'Collapse', route: 'components-collapse' },
      { title: 'Dropdown', route: 'components-dropdown' },
      { title: 'Embed', route: 'components-embed' },
      { title: 'Image', route: 'components-image' },
      { title: 'List Group', route: 'components-list-group' },
      { title: 'Media Objects', route: 'components-media' },
      { title: 'Modal', route: 'components-modal' },
      { title: 'Nav', route: 'components-nav' },
      { title: 'Overlay', route: 'components-overlay' },
      { title: 'Pagination', route: 'components-pagination' },
      { title: 'Pagination Nav', route: 'components-pagination-nav' },
      { title: 'Pill', route: 'components-pill' },
      { title: 'Pill Badge', route: 'components-pill-badge' },
      { title: 'Popover', route: 'components-popover' },
      { title: 'Progress', route: 'components-progress' },
      { title: 'Sidebar', route: 'components-sidebar' },
      { title: 'Spinner', route: 'components-spinner' },
      { title: 'Tab', route: 'components-tab' },
      { title: 'Time', route: 'components-time' },
      { title: 'Timeline', route: 'components-timeline' },
      { title: 'Toasts', route: 'components-toasts' },
      { title: 'Tooltip', route: 'components-tooltip' },
    ],
  },
  {
    title: 'Extensions',
    icon: 'FolderIcon',
    children: [
      { title: 'Sweet Alerts', route: 'extensions-sweet-alert' },
      { title: 'Drag & Drop', route: 'extensions-drag-and-drop' },
      { title: 'Swiper', route: 'extensions-swiper' },
      { title: 'Clipboard', route: 'extensions-clipboard' },
      { title: 'Context Menu', route: 'extensions-context-menu' },
      { title: 'Toastification', route: 'extensions-toastification' },
      { title: 'I18n', route: 'extensions-i18n' },
      { title: 'Slider', route: 'extensions-slider' },
      { title: 'Tour', route: 'extensions-tour' },
      { title: 'Tree', route: 'extensions-tree' },
    ],
  },
  {
    title: 'UI',
    icon: 'FolderIcon',
    children: [
      { title: 'Typography', route: 'ui-typography' },
      { title: 'Colors', route: 'ui-colors' },
      { title: 'Feather', route: 'ui-feather' },
    ],
  },
  {
    header: 'Others',
  },
  {
    title: 'Canlendar',
    route: 'apps-calendar',
    icon: 'CalendarIcon',
  },
  {
    title: 'Email',
    route: 'apps-email',
    icon: 'MailIcon',
  },
  {
    title: 'Todo',
    route: 'apps-todo',
    icon: 'CheckCircleIcon',
  },
  {
    title: 'Message',
    icon: 'MessageSquareIcon',
    children: [
      { title: 'Chat', route: 'apps-chat' },
      { title: 'SNS Chat', route: 'apps-sns-chat' },
    ],
  },
  {
    title: 'E-Commerce',
    icon: 'ShoppingCartIcon',
    children: [
      { title: 'Shop', route: 'apps-e-commerce-shop' },
      { title: 'Wishlist', route: 'apps-e-commerce-wishlist' },
      { title: 'Checkout', route: 'apps-e-commerce-checkout' },
    ],
  },
  {
    title: 'Invoice',
    icon: 'FolderIcon',
    children: [
      { title: 'Invoice List', route: 'apps-invoice-list' },
      { title: 'Invoice Add', route: 'apps-invoice-add' },
    ],
  },
  {
    title: 'Dashboard',
    icon: 'FolderIcon',
    children: [
      { title: 'Analytics', route: 'dashboard-analytics' },
      { title: 'E-Commerce', route: 'dashboard-ecommerce' },
    ],
  },
  {
    title: 'Card',
    icon: 'FolderIcon',
    children: [
      { title: 'Basic Card', route: 'card-basic' },
      { title: 'Advance Card', route: 'card-advance' },
      { title: 'Statistics Cards', route: 'card-statistic' },
      { title: 'Analytics Cards', route: 'card-analytic' },
      { title: 'Card Actions', route: 'card-action' },
    ],
  },
  {
    title: 'Page Layout',
    icon: 'FolderIcon',
    children: [
      {
        title: 'Layout Collapsed Menu',
        route: 'page-layout-collapsed-menu',
      },
      { title: 'Boxed Layout', route: 'page-layout-boxed-layout' },
      { title: 'Layout Without Menu', route: 'page-layout-without-menu' },
      { title: 'Layout Empty', route: 'page-layout-layout-empty' },
      { title: 'Layout Blank', route: 'page-layout-layout-blank' },
    ],
  },
]
